<template>
  <div>
    <navbar />
    <div class="content">
      <div class="row" v-if="plants != null">
        <div class="col-lg-4 col-md-12 col-sm-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <i class="fa fa-tractor text-success"></i>
                  </div>
                </div>
                <div class="col-7 col-md-8">
                  <div class="numbers">
                    <p class="card-category">Jouw boerderijniveau</p>
                    <p class="card-title">Level {{ calculatedStats.level }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <hr />
              <div class="stats">
                <i class="fa fa-arrow-alt-circle-up"></i>
                Nog {{ calculatedStats.requiredUnitillNext }} punten tot volgend
                niveau
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <i class="fa fa-crown text-warning"></i>
                  </div>
                </div>
                <div class="col-7 col-md-8">
                  <div class="numbers">
                    <p class="card-category">Jouw ervaringspunten</p>
                    <p class="card-title">{{ calculatedStats.xp }} punten</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <hr />
              <div class="stats">
                <i class="fa fa-coins"></i>
                {{ calculatedStats.todayXP }} punten vandaag
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <i class="fa fa-users text-danger"></i>
                  </div>
                </div>
                <div class="col-7 col-md-8">
                  <div class="numbers">
                    <p class="card-category">Jouw score hoort in de top</p>
                    <p class="card-title">{{ calculatedStats.topPercent }}%</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <hr />
              <div class="stats">
                <i class="fa fa-sync"></i>
                Laatste update {{ today }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="plants != null">
        <div class="row" v-if="messages.length > 0">
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
            v-for="message in messages"
            :key="message.index"
          >
            <div class="card card-stats">
              <div class="card-header">
                <div class="icon-big text-center icon-warning">
                  <i class="fa fa-exclamation-circle text-danger"></i>
                </div>
                <p class="notification badge">{{ message.counter }}</p>
              </div>

              <div class="card-body">
                <div class="numbers">
                  <p class="card-category">Let op!</p>
                  <p class="card-title">
                    Jouw {{ message.plantName }} heeft
                    {{ message.val.action }} nodig!
                  </p>
                  <p></p>
                </div>
              </div>

              <div class="card-footer">
                <hr />
                <div class="stats">
                  <router-link
                    :to="'plants/' + message.plantID"
                    type="button"
                    class="btn btn-primary"
                    >Bekijk plant</router-link
                  >

                  <button
                    type="button"
                    @click="removeMessage(message.plantID, message.key)"
                    class="btn btn-danger float-right"
                  >
                    Verwijder
                  </button>
                  <div class="pt-2">
                    <i class="fa fa-calendar-alt"></i> Gestuurd op
                    {{ message.val.time }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-4 col-lg-4 order-12 order-lg-1">
            <div class="card card-stats w-100" v-if="messages.length == 0">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-4">
                    <div class="icon-big text-center icon-warning">
                      <i class="fa fa-check text-success"></i>
                    </div>
                  </div>
                  <div class="col-7 col-md-8">
                    <div class="numbers pb-4">
                      <p class="card-category">Goed gedaan!</p>
                      <p class="card-title">Er zijn geen berichten</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Algemene statistieken</h5>
              </div>
              <div class="card-body">
                <li class="card-category" v-if="this.userStats">
                  Aantal dagen actief:
                  {{ Object.values(this.userStats).length }}
                </li>
                <li class="card-category">
                  Hoogste aantal punten verdiend:
                  {{ calculatedStats.highestXP }} punten
                </li>
                <li class="card-category">
                  Aantal planten:
                  {{ Object.keys(plants).length }}
                </li>
                <li class="card-category">
                  Aantal logins:
                  {{ calculatedStats.numberOfLogins }}
                </li>
              </div>
              <div class="card-header">
                <h5 class="card-title">Statistieken van alle planten</h5>
              </div>
              <div class="card-body">
                <li class="card-category">
                  Gemiddelde luchtvochtigheid: {{ getAverage("humidity") }} %
                </li>
                <li class="card-category">
                  Gemiddelde temperatuur:
                  {{ getAverage("temperature") }} °C
                </li>
                <li class="card-category">
                  Gemiddelde grondvocht:
                  {{ getAverage("water") }} %
                </li>
                <li class="card-category">
                  Gemiddelde lux:
                  {{ getAverage("sunlight") }} lux
                </li>
              </div>

              <div class="card-header">
                <h5 class="card-title">Uitgevoerde acties</h5>
              </div>
              <div class="card-body">
                <vc-donut
                  background="white"
                  :size="50"
                  unit="%"
                  :thickness="20"
                  has-legend
                  legend-placement="top"
                  :sections="barSections"
                  :total="
                    barSections[0].value +
                    barSections[1].value +
                    barSections[2].value
                  "
                  :start-angle="0"
                  :auto-adjust-text-size="true"
                  @section-mouseenter="handleSectionMouse"
                >
                  <p id="lableContent" class="card-category"></p>
                  <h2 id="donutContent"></h2>
                </vc-donut>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 order-1 order-lg-12">
            <div class="card card-chart">
              <div class="card-header">
                <div class="h3 text-center icon-success d-inline mr-4">
                  <i class="fa fa-seedling text-success"></i>
                </div>
                <h5 class="card-title d-inline">Planten</h5>
                <p class="card-category">Een overzicht van alle plantjes</p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="(plant, key) in plants"
                    :key="plant.index"
                  >
                    <div class="thumbnail">
                      <router-link :to="'plants/' + key">
                        <img
                          :src="getLatestImage(key)"
                          :alt="
                            'Laatst gemaakte afbeelding van' + plant.plantName
                          "
                          width="100%"
                        />
                      </router-link>
                      <div class="caption text-center">
                        <p>{{ plant.plantName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <hr />
                <div class="stats">
                  <i class="fa fa-sync"></i>
                  Laatste update {{ today }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <onboarding v-else-if="onboarding" :userdata="user" />
      <div class="container" style="height: 100vh" v-else>
        <div class="row h-100 justify-content-center align-items-center">
          <div class="text-center">
            <img
              src="../assets/logo.png"
              class="d-block"
              alt="The Green's Gambit"
              height="150px"
            />
            <b-spinner
              variant="primary"
              style="width: 6rem; height: 6rem"
              label="Loading"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Onboarding from "../components/onboarding.vue";
import { projectDB, projectAuth } from "../js/firebaseConfig";
import router from "../router";

export default {
  name: "Home",
  components: { Navbar, Onboarding },

  data() {
    return {
      user: {},
      userStats: {},
      allUserStats: {},
      plants: null,
      onboarding: false,
      barSections: [
        { label: "Water", value: 1, color: "#51cbce" },
        { label: "Voeding", value: 1, color: "#fbc658" },
        { label: "Meten", value: 1, color: "#ef8157" },
      ],
    };
  },
  methods: {
    removeMessage(plantID, key) {
      const dbRef = projectDB.ref();
      dbRef
        .child("users")
        .child(this.user.uid)
        .child("plants")
        .child(plantID)
        .child("messages")
        .child(key)
        .remove();
    },
    handleSectionMouse(section, event) {
      document.getElementById("lableContent").innerHTML = `${section.label}`;
      console.log("enter");
      document.getElementById("donutContent").innerHTML =
        `${section.value}` + "x";
    },
    todayKey() {
      var day = new Date();
      function twoDigit(n) {
        return (n < 10 ? "0" : "") + n;
      }
      return (
        day.getFullYear() +
        "" +
        twoDigit(day.getMonth() + 1) +
        "" +
        twoDigit(day.getDate())
      );
    },
    getAverage(key) {
      //get average of all stats in every plant.
      var avg = 0;
      var total = 0;
      var numberOfItems = 0;
      for (var p = 0; p < Object.keys(this.plants).length; p++) {
        var currentPlant = this.plants[Object.keys(this.plants)[p]];

        if ("historyDay" in currentPlant) {
          for (
            var i = 0;
            i < Object.keys(currentPlant.historyDay).length;
            i++
          ) {
            var currentDay = Object.keys(currentPlant.historyDay)[i];
            if (key in currentPlant.historyDay[currentDay]) {
              var latestObject = currentPlant.historyDay[currentDay][key];
              for (var k = 0; k < Object.keys(latestObject).length; k++) {
                numberOfItems++;
                total += Object.values(latestObject)[k];
              }
            }
          }
        }
        avg = total / numberOfItems;
        avg = Math.round(avg * 10) / 10;
        if (isNaN(avg)) avg = 0;
        return avg;
      }
    },
    fillBarData() {
      // fill donut with combined number of actions
      if (this.plants != null) {
        var totalWater = 1;
        var totalFeed = 1;
        var totalMeasure = 1;
        for (var p = 0; p < Object.keys(this.plants).length; p++) {
          var currentPlant = this.plants[Object.keys(this.plants)[p]];

          if ("plantStats" in currentPlant) {
            if ("water" in currentPlant.plantStats)
              totalWater += currentPlant.plantStats["water"];
            if ("voeding" in currentPlant.plantStats)
              totalFeed += currentPlant.plantStats["voeding"];
            if ("meten" in currentPlant.plantStats)
              totalMeasure += currentPlant.plantStats["meten"];
          }
        }

        this.barSections[0].value = totalWater;
        this.barSections[1].value = totalFeed;
        this.barSections[2].value = totalMeasure;
      }
    },
    getLatestImage(plantID) {
      if (this.plants != "") {
        var plantObj = this.plants[plantID];
        if ("historyDay" in plantObj) {
          var latestDay = Object.keys(plantObj.historyDay)[
            Object.keys(plantObj.historyDay).length - 1
          ];
          if ("imageURL" in plantObj.historyDay[latestDay]) {
            var latestObject = plantObj.historyDay[latestDay]["imageURL"];

            return latestObject[
              Object.keys(latestObject)[Object.keys(latestObject).length - 1]
            ];
          } else return require("../assets/placeholder.jpg");
        }else return require("../assets/placeholder.jpg");
      }
    },
  },

  beforeCreate() {
    projectAuth.onAuthStateChanged((_user) => {
      if (_user) {
        this.user = _user;
        var userData = projectDB.ref("users/" + _user.uid);
        userData.on("value", (snapshot) => {
          if (snapshot.exists()) {
            var data = snapshot.val();
            this.plants = data.plants;
            this.userStats = data.userStats;
            this.fillBarData();
          } else {
            userData.set({ username: _user.displayName, email: _user.email });
          }
        });
        var plantData = projectDB.ref("users/" + _user.uid + "/plants");
        plantData
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              this.onboarding = false;
            } else {
              this.onboarding = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });

        var allUserRef = projectDB.ref("allUserStats");
        allUserRef
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              this.allUserStats = snapshot.val();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        router.push("/auth");
      }
    });
  },
  computed: {
    today: function () {
      var day = new Date();
      return (
        day.getDate() + "/" + (day.getMonth() + 1) + "/" + day.getFullYear()
      );
    },

    messages: function () {
      //retrieve all messages and combine them for the messageboard
      if (this.plants) {
        var foundMessages = [];
        for (var i = 0; i < Object.keys(this.plants).length; i++) {
          var plantKey = Object.keys(this.plants)[i];
          if ("messages" in this.plants[plantKey]) {
            for (
              var k = 0;
              k < Object.keys(this.plants[plantKey].messages).length;
              k++
            ) {
              var messageKey = Object.keys(this.plants[plantKey].messages)[k];

              foundMessages.push({
                val: this.plants[plantKey].messages[messageKey],
                plantID: plantKey,
                plantName: this.plants[plantKey].plantName,
                key: messageKey,
              });
            }
          }
        }
        var calculatedMessage = [];
        var filteredArray = [];
        if (foundMessages.length > 0) {
          //get all plantid's in a array
          for (var l = 0; l < foundMessages.length; l++) {
            const count = foundMessages.filter(function (item) {
              if (item.plantID === foundMessages[l].plantID) {
                return true;
              } else {
                return false;
              }
            });
            if (filteredArray.length > 0) {
              var canAdd = true;
              for (var j = 0; j < filteredArray.length; j++) {
                if (filteredArray[j][0].plantID === count[0].plantID) {
                  //compare to-be-added whith what is already in so it can be filtered
                  canAdd = false;
                }
              }
              if (canAdd) filteredArray.push(count); //only add if there is no same plant in array
            } else filteredArray.push(count);
          }
        }
        for (var x = 0; x < filteredArray.length; x++) {
          filteredArray[x][0].counter = filteredArray[x].length;
          calculatedMessage.push(filteredArray[x][0]);
        }
      }
      //return foundMessages;
      return calculatedMessage;
    },
    calculatedStats: function () {
      //calculate stats for this user
      var calculated = {
        xp: 0,
        highestXP: 0,
        level: 1,
        requiredUnitillNext: 200,
        todayXP: 0,
        topPercent: 80,
        numberOfLogins: 1,
      };
      if (this.userStats) {
        for (var i = 0; i < Object.keys(this.userStats).length; i++) {
          var dayKey = Object.keys(this.userStats)[i];

          var todayKey = this.todayKey();

          if ("XP" in this.userStats[dayKey]) {
            if ("xpValue" in this.userStats[dayKey].XP) {
              if (dayKey == todayKey) {
                calculated.todayXP = this.userStats[dayKey].XP.xpValue;
              }
              if (this.userStats[dayKey].XP.xpValue > calculated.highestXP) {
                calculated.highestXP = this.userStats[dayKey].XP.xpValue;
              }
              calculated.xp += this.userStats[dayKey].XP.xpValue;
            }
          }
          if ("loginHistory" in this.userStats[dayKey]) {
            calculated.numberOfLogins += Object.keys(
              this.userStats[dayKey].loginHistory
            ).length;
          }
        }
        calculated.requiredUnitillNext = 100;
        for (var l = 1; l < 200; l++) {
          if (calculated.xp < calculated.requiredUnitillNext + (l - 1) * 100) {
            calculated.level = l;
            calculated.requiredUnitillNext =
              calculated.requiredUnitillNext + l * 100 - calculated.xp;
            break;
          }

          calculated.requiredUnitillNext =
            calculated.requiredUnitillNext + l * 100;
        }
      }
      if (this.allUserStats) {
        var numberUnder = 0;
        var numberAbove = 0;
        for (var k = 0; k < Object.keys(this.allUserStats).length; k++) {
          var playerKey = Object.keys(this.allUserStats)[k];

          if ("XP" in this.allUserStats[playerKey]) {
            if ("xpValue" in this.allUserStats[playerKey].XP) {
              if (this.allUserStats[playerKey].XP.xpValue >= calculated.xp)
                numberAbove++;
              else numberUnder++;
            }
          }
        }
        var total = numberUnder + numberAbove;
        var percentage = (numberAbove / total) * 99 + 1;
        if (isNaN(percentage)) percentage = 0;
        calculated.topPercent = percentage;
      }
      return calculated;
    },
  },
};
</script>
<style scoped >
.content {
  background: #f4f3ef;
  background-image: url("~@/assets/backgroundfarm.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom right;

  padding: 100px 10px 0px 10px;
  min-height: 100vh;
}
@media (min-width: 699px) {
  .content {
    padding: 100px 50px 0px 50px;
  }
}
.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 10px;

  background: #fbc658;
  color: white;
}
</style>