import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import PlantDetail from '../views/PlantDetail.vue'
import noplantfound from '../views/noplant.vue'
import newPlant from '../views/newPlant.vue'
import { getUser } from '../js/firebaseConfig'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,

  },
  {
    path: '/new-plant',
    name: 'newPlant',
    component: newPlant,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/plants/:id',
    name: 'PlantDetail',
    component: PlantDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/noplantfound',
    name: 'noplantfound',
    component: noplantfound,
    meta: {
      requiresAuth: true
    }
  },
  

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!getUser()) {
      router.push('/auth')
    }
    else next()
  } else { // no auth required
    
    next()
  }
})


export default router
