<template>
  <div class="wrapper">
    <div class="container">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-8 m-2 card p-4 bg-light">
          <img
            class="card-img-top"
            src="../assets/cardimg2.jpg"
            alt="Kies een plant"
            height="100px"
          />
          <div class="card-img-overlay">
            <h2 class="text-center card-title text-light pt-4">
              Een nieuwe plant
            </h2>
          </div>
          <p class="text-center card-text pt-3">
            Je mag nu een plant kiezen, iedere plant heeft zijn eigen voorkeuren
            en moeilijkheidsgraad.<br />
            Kies uit een van de onderstaande planten.
          </p>
          <div class="row justify-content-center">
            <div
              v-for="plant in plantsToChoose"
              :key="plant.index"
              class="col-lg-6 p-0"
            >
              <div class="card m-2">
                <img
                  class="card-img-top"
                  :src="require('../assets/' + plant.imgURL)"
                  :alt="plant.name"
                />
                <div class="card-body">
                  <h5 class="card-title">{{ plant.name }}</h5>
                  <p class="card-text">{{ plant.plantInformation }}</p>

                  <button
                    type="button"
                    @click="choosePlant(plant)"
                    class="btn btn-outline-success"
                  >
                    Kies
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectDB, projectAuth } from "../js/firebaseConfig";
import router from '../router';

export default {
  name: "newPlant",

  data() {
    return {
      user: {},
      plantsToChoose: [
        {
          name: "Tuinkers",
          plantInformation:
            "Tuinkers is een eenjarige plant die heel snel kiemt en groeit. Snel resultaat dus! Je kunt er vooral in de koelere maanden van het jaar regelmatig blaadjes van oogsten. Tuinkers houdt van een koele en vochtige grond en zeker niet te veel zonlicht.",
          imgURL: "tuinkers.jpg",
        },
        {
          name: "Radijs",
          plantInformation:
            "Ben je een leek in de kunst van het moestuinieren? Start dan met radijs, want daarmee kan het bijna niet misgaan. Zaai van maart tot augustus; radijsjes kiemen vervolgens snel en groeien makkelijk. Oogsten kan al na zes tot acht weken. De grond hoeft niet kletsnat te zijn, maar ook zeker niet uitgedroogd.",
          imgURL: "radijsjes.jpg",
        },
        {
          name: "Courgette",
          plantInformation:
            "Voor courgettes hoef je geen groene vingers te hebben; als de plant eenmaal aanslaat, zoekt hij vanzelf zijn weg door je moestuin. Hij groeit echt goed als de temperaturen wat hoger liggen. Een courgette groeit hartstikke snel; na zo’n zes weken is oogsten al mogelijk. Vocht zorgt bij deze moestuinplanten vaak voor meeldauw. Geef ze genoeg water, maar houd een oogje in het zeil. ",
          imgURL: "courgettes.jpg",
        },
        {
          name: "Wortelen",
          plantInformation:
            "Het kan even duren voor je ze kunt oogsten (zo’n drie maanden), maar dan heb je ook wat.  Geduld is bij wortelen wel geboden; kiemen kan al wel zo’n drie tot vier weken duren. Verder heb je er weinig omkijken naar, ze vallen onder de makkelijke groenten. Wortels hebben niet veel voedsel nodig en soms wat water. Alvast wat lekkers kweken voor Amerigo?",
          imgURL: "wortelen.jpg",
        },
      ],
    };
  },
  beforeCreate() {
    projectAuth.onAuthStateChanged((_user) => {
      if (_user) {
        this.user = _user;
      } else {
        //router.push("/auth");
      }
    });
  },
  methods: {
    choosePlant(plant) {
      //get a new plant id (from stack)
      var ID;
      const dbRef = projectDB.ref("availablePlantID");
      dbRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            var data = snapshot.val();
            ID = Object.values(data)[0]; //get first available plant id -> if no plants are available then there will be no snapshot.
            console.log(ID);
            this.initialObj(plant, ID);
            dbRef.child(Object.keys(data)[0]).remove(); // remove plant id from stack
          } else {
            console.log("No plant available at the moment!");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    todayKey() {
      var day = new Date();
      function twoDigit(n) {
        return (n < 10 ? "0" : "") + n;
      }
      return (
        day.getFullYear() +
        "" +
        twoDigit(day.getMonth() + 1) +
        "" +
        twoDigit(day.getDate())
      );
    },

    initialObj(plant, nextPlantID) {
      // Get a key for a new plant.
      var newPlantKey = projectDB
        .ref("users/" + this.user.uid + "/plants")
        .push().key;

      var dayKey = this.todayKey();

      projectDB
        .ref("users")
        .child(this.user.uid)
        .child("plants")
        .child(newPlantKey)
        .child("chosenHistoryDay")
        .set(dayKey)
        .then(() => {
          projectDB
            .ref("users")
            .child(this.user.uid)
            .child("plants")
            .child(newPlantKey)
            .child("plantName")
            .set(plant.name);
        })
        .then(() => {
          projectDB
            .ref("users")
            .child(this.user.uid)
            .child("plants")
            .child(newPlantKey)
            .child("plantNumber")
            .set(nextPlantID);
        })
        .then(() => {
          router.push('/')
          })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.wrapper {
  background: #f4f3ef;
  background-image: url("~@/assets/backgroundfarm.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom left;
  height: auto;
}
</style>
