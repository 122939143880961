<template>
  <div class="content" v-if="plantInfo">
    <navbar />
    <!-- Top row with plant info -->
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-12 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="card-header text-center">
              <div class="icon-big text-center icon-success d-inline mr-2">
                <i class="fa fa-seedling text-success"></i>
              </div>
              <h3 class="card-title d-inline">{{ plantInfo.plantName }}</h3>
              <p class="card-category" v-if="'historyDay' in this.plantInfo">
                Gezaaid op
                {{ Object.values(this.plantInfo.historyDay)[0].day }} door
                {{ displayName }}
              </p>
              <p class="card-category" v-else>
                Gezaaid door
                {{ displayName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Latest data row -->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="fa fa-thermometer-three-quarters text-primary"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category">Temperatuur</p>
                  <p class="card-title">{{ getLatestItem("temperature") }}°C</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr />
            <div class="stats" v-if="'historyDay' in this.plantInfo">
              <i class="fa fa-sync"></i>
              Laatste meting om
              {{ getLatestItem("measureTimes") }}
            </div>
            <div class="stats" v-else>
              <i class="fa fa-exclamation-circle"></i>
              Er is nog geen meetgegeven beschikbaar
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="fa fa-sun text-warning"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category">Zonlicht</p>
                  <p class="card-title">{{ getLatestItem("sunlight") }} Lux</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr />
            <div class="stats" v-if="'historyDay' in this.plantInfo">
              <i class="fa fa-sync"></i>
              Laatste meting om
              {{ getLatestItem("measureTimes") }}
            </div>
            <div class="stats" v-else>
              <i class="fa fa-exclamation-circle"></i>
              Er is nog geen meetgegeven beschikbaar
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="fa fa-tint text-primary"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category">Luchtvochtigheid</p>
                  <p class="card-title">{{ getLatestItem("humidity") }}%</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr />
            <div class="stats" v-if="'historyDay' in this.plantInfo">
              <i class="fa fa-sync"></i>
              Laatste meting om
              {{ getLatestItem("measureTimes") }}
            </div>
            <div class="stats" v-else>
              <i class="fa fa-exclamation-circle"></i>
              Er is nog geen meetgegeven beschikbaar
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="fa fa-faucet text-danger"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category">Grondvocht</p>
                  <p class="card-title">{{ getLatestItem("water") }}%</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr />
            <div class="stats" v-if="'historyDay' in this.plantInfo">
              <i class="fa fa-sync"></i>
              Laatste meting om
              {{ getLatestItem("measureTimes") }}
            </div>
            <div class="stats" v-else>
              <i class="fa fa-exclamation-circle"></i>
              Er is nog geen meetgegeven beschikbaar
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Row with chart and stats -->
    <div class="row">
      <div class="col-md-12">
        <div class="card" v-if="'historyDay' in this.plantInfo">
          <div class="card-header">
            <h5 class="card-title d-inline">Grafiek</h5>
            <p class="card-category d-inline">
              {{ plantInfo.historyDay[plantInfo.chosenHistoryDay].day }}
              <b-dropdown
                id="dateChoser"
                text="Kies een andere dag"
                class="m-md-2"
              >
                <b-dropdown-item
                  @click="setDate(index)"
                  v-for="(day, index) in plantInfo.historyDay"
                  :key="index"
                  >{{ day.day }}</b-dropdown-item
                >
              </b-dropdown>
            </p>
          </div>
          <div class="card-body">
            <Chart
              :chartDatasets="chartDatasets"
              :labels="labels"
              :imgURL="
                plantInfo.historyDay[this.plantInfo.chosenHistoryDay].imageURL
              "
            />
          </div>
          <div class="card-footer">
            <hr />
            <div class="stats">
              <i class="fa fa-history"></i> Totaal
              {{
                Object.keys(
                  plantInfo.historyDay[plantInfo.chosenHistoryDay].measureTimes
                ).length
              }}
              metingen op deze dag gemaakt
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Action button row -->
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Acties
              <b-spinner
                id="actionLoader"
                style="visibility: hidden"
                variant="success"
                label="Spinning"
              ></b-spinner>
            </h5>
            <p class="card-category">Verzorg jouw plant</p>
          </div>
          <div class="card-body">
            <div>
              <span id="metenSpan" class="d-inline-block" tabindex="0">
                <button
                  variant="primary"
                  class="btn btn-dark text-center mb-2"
                  id="meten"
                  @click="actionButton('meten', 3600000)"
                >
                  Start een meting <span class="text-success">+10 punten</span>
                </button>
              </span>
              <b-tooltip target="metenSpan" triggers="hover" placement="right"
                >Meet de luchtvochtigheid, temperatuur, grondvocht, Lux en maakt
                een foto.</b-tooltip
              >
            </div>
            <div>
              <span id="voedingSpan" class="d-inline-block" tabindex="0">
                <button
                  variant="primary"
                  class="btn btn-dark text-center mb-2"
                  id="voeding"
                  disabled
                  @click="actionButton('voeding', 3600000 * 24)"
                >
                  Geef de plant extra voeding
                  <span class="text-success">+100 punten</span>
                </button>
              </span>
              <b-tooltip target="voedingSpan" triggers="hover" placement="right"
                >Beschikbaar vanaf level 10</b-tooltip
              >
            </div>
            <div>
              <span id="waterSpan" class="d-inline-block" tabindex="0">
                <button
                  variant="primary"
                  class="btn btn-dark text-center mb-2"
                  id="water"
                  @click="actionButton('water', 3600000 * 24)"
                >
                  Geef de plant water
                  <span class="text-success">+50 punten</span>
                </button>
              </span>
              <b-tooltip target="waterSpan" triggers="hover" placement="right"
                >Geeft de plant genoeg water voor een dag</b-tooltip
              >
            </div>
          </div>
        </div>
        <div class="card w-100" v-if="'historyDay' in this.plantInfo">
          <div class="card-header">
            <h5 class="card-title">Statistieken</h5>
          </div>
          <div class="card-body">
            <li class="card-category">
              Aanmaakdatum van deze plant:
              {{ Object.values(this.plantInfo.historyDay)[0].day }}
            </li>
            <li class="card-category">
              Aantal dagen actief:
              {{ Object.values(this.plantInfo.historyDay).length }}
            </li>
            <li class="card-category">
              Gemiddelde luchtvochtigheid: {{ getAverage("humidity") }} %
            </li>
            <li class="card-category">
              Gemiddelde temperatuur:
              {{ getAverage("temperature") }} °C
            </li>
            <li class="card-category">
              Gemiddelde grondvocht:
              {{ getAverage("water") }} %
            </li>
            <li class="card-category">
              Gemiddelde lux:
              {{ getAverage("sunlight") }} lux
            </li>
          </div>

          <div class="card-header">
            <h5 class="card-title">Acties</h5>
          </div>
          <div class="card-body">
            <vc-donut
              background="white"
              :size="50"
              unit="%"
              :thickness="20"
              has-legend
              legend-placement="top"
              :sections="barSections"
              :total="
                barSections[0].value +
                barSections[1].value +
                barSections[2].value
              "
              :start-angle="0"
              :auto-adjust-text-size="true"
              @section-mouseenter="handleSectionMouse"
            >
              <p id="lableContent" class="card-category"></p>
              <h2 id="donutContent"></h2>
            </vc-donut>
          </div>
        </div>
        <div class="card w-100" v-else>
          <div class="card-header">
            <h5 class="card-title">Statistieken</h5>
          </div>
          <div class="card-body">
            <p class="card-category">Nog geen gegevens beschikbaar</p>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card card-chart" v-if="'historyDay' in this.plantInfo">
          <div class="card-header">
            <h5 class="card-title">Gallerij</h5>
            <p class="card-category">
              Dit zijn de {{ this.images.img.length }} laatst gemaakte
              afbeeldingen (van totaal {{ this.images.totalItems }})
            </p>
          </div>
          <div class="card-body">
            <div class="row mt-4">
              <div
                class="col-md-4"
                v-for="image in images.img"
                :key="image.index"
              >
                <div class="thumbnail">
                  <img
                    :src="image.url"
                    alt="Laatst gemaakte afbeeldingen"
                    width="100%"
                  />
                  <div class="caption">
                    <p>{{ image.discription }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <hr />
            <div class="card-stats">
              <i class="fa fa-calendar-alt"></i> Gekozen dag:
              {{ plantInfo.historyDay[plantInfo.chosenHistoryDay].day }}
            </div>
          </div>
        </div>
        <div class="card card-chart" v-else>
          <div class="card-header">
            <h5 class="card-title">Gallerij</h5>
            <p class="card-category">
              Er zijn nog geen afbeeldingen beschikbaar
            </p>
          </div>
          <div class="card-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { projectDB, projectAuth, getUser } from "../js/firebaseConfig";
import Chart from "../components/chart.vue";
import Navbar from "../components/navbar.vue";
import Vue from "vue";

export default {
  components: { Navbar, Chart },
  data() {
    return {
      userID: "",
      displayName: "",
      plantInfo: "",
      intervalWater: null,
      intervalFeed: null,
      intervalMeasurement: null,
      imageItems: 0,
      listenerArr: [],
      barSections: [
        //this is for the donutchart
        { label: "Water", value: 1, color: "#51cbce" },
        { label: "Voeding", value: 1, color: "#fbc658" },
        { label: "Meten", value: 1, color: "#ef8157" },
      ],
      chartDatasets: [
        // this is for the big chart
        {
          data: [],
          smooth: true,
          showPoints: true,
          fill: false,
          className: "curve1",
        },
        {
          data: [],
          smooth: true,
          showPoints: true,
          className: "curve2",
        },
        {
          data: [],
          smooth: true,
          showPoints: true,
          className: "curve3",
        },
        {
          data: [],
          smooth: true,
          showPoints: true,
          className: "curve4",
        },
      ],

      labels: {
        xLabels: [],
        yLabels: 11,
        yLabelsTextFormatter: (val) => Math.round(val * 100) / 100,
      },
    };
  },

  computed: {
    newData: function () {
      return this.oldImageItems < this.newImageItems;
    },
    // get all images and put them in a array in a object
    images: function () {
      var imgLocation = this.plantInfo.historyDay[
        this.plantInfo.chosenHistoryDay
      ].imageURL;
      var images = [];
      var timeLocation = this.plantInfo.historyDay[
        this.plantInfo.chosenHistoryDay
      ].measureTimes;
      var date = this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay].day;
      if (imgLocation != null) {
        
        var timeKeys = Object.keys(timeLocation);
        var imgKeys = Object.keys(imgLocation);
        var itemtimes = timeKeys.slice(0).slice(-6);
        var items = imgKeys.slice(0).slice(-6);
        for (var i = 0; i < items.length; i++) {
          images.push({
            url: imgLocation[items[i]],
            discription: date + " om " + timeLocation[itemtimes[i]],
          });
        }
      }
      return { img: images, totalItems: images.length };
    },
  },
  watch: {
    "$route.params.id": function (newkey, old) {
      //remove old listener
      var plantData = projectDB.ref("users/" + this.userID + "/plants/" + old);
      plantData.off("value");
      this.setListener(newkey); //then set a new listener
    },
    plantInfo: function (newVal, oldVal) {
      //listen to changes in plantinfo to check if there is a new measurement in
      var oldkeys = 0;
      if ("historyDay" in newVal) {
        var days = Object.keys(newVal.historyDay);
        var latestDay = newVal.historyDay[days[days.length - 1]];
        if ("imageURL" in latestDay) {
          var keys = Object.keys(latestDay.imageURL).length; //I chose imageURL, but any measurementdata will work
        }
      }
      if (oldVal != "") {
        if ("historyDay" in oldVal) {
          var oldDays = Object.keys(oldVal.historyDay);
          var oldLatestDay = oldVal.historyDay[oldDays[oldDays.length - 1]];
          if ("imageURL" in oldLatestDay) {
            oldkeys = Object.keys(oldLatestDay.imageURL).length;
          }
        }
      }
      if (keys > oldkeys) {
        var actionLoader = document.getElementById("actionLoader");
        if (actionLoader) {
          actionLoader.style.visibility = "hidden";
        }
      }
    },
  },

  beforeCreate() {
    //check if there is a active user
    projectAuth.onAuthStateChanged((_user) => {
      if (_user) {
        // if there is a user, get ONCE all the plants of that user
        this.userID = _user.uid;
        this.displayName = _user.displayName;
        this.setListener(this.$route.params.id);
      }
    });
  },

  methods: {
    // this function is needed to format data in array's to be proccesed by trendchard
    // bool is true in sunlight arr to fit in range +- 0-100 by dividing by 300
    makeArrayOfObj(obj, bool) {
      var keys = Object.keys(obj);
      var array = [];
      keys.forEach((key) => {
        if (bool) {
          array.push(obj[key] / 300);
        } else array.push(obj[key]);
      });
      return array;
    },
    //donutchart mousemove with label
    handleSectionMouse(section, event) {
      document.getElementById("lableContent").innerHTML = `${section.label}`;
      document.getElementById("donutContent").innerHTML =
        `${section.value}` + "x";
    },
    getLatestItem(key) {
      if ("historyDay" in this.plantInfo) {
        var latestDay = Object.keys(this.plantInfo.historyDay)[
          Object.keys(this.plantInfo.historyDay).length - 1
        ];
        if (key in this.plantInfo.historyDay[latestDay]) {
          var latestObject = this.plantInfo.historyDay[latestDay][key];
          return latestObject[
            Object.keys(latestObject)[Object.keys(latestObject).length - 1]
          ];
        }
      }
    },
    
    setListener(id) {
      const dbRef = projectDB.ref();
      dbRef
        .child("users")
        .child(this.userID)
        .child("plants")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            //try to get the id of the plant with page is being visited
            var data = snapshot.val();

            //if there is a plant, set a permanent listener to that data. else -> go to not found page,
            if (id in data) {
              var plantData = projectDB.ref(
                "users/" + this.userID + "/plants/" + id
              );
              plantData.on("value", (snapshot) => {
                var data = snapshot.val();
                this.plantInfo = data;
                this.fillGraphData();
                this.checkButtons();
                this.fillDonutData();
              });
            } else {
              this.$router.push("/noplantfound");
            }
          } else {
            console.log("No snapshot available");
            this.$router.push("/noplantfound");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // this is to get all data from the chosen plant and return the avg of all data.
    getAverage(key) {
      if ("historyDay" in this.plantInfo) {
        var avg = 0;
        var total = 0;
        var numberOfItems = 0;
        for (
          var i = 0;
          i < Object.keys(this.plantInfo.historyDay).length;
          i++
        ) {
          var currentDay = Object.keys(this.plantInfo.historyDay)[i];
          if (key in this.plantInfo.historyDay[currentDay]) {
            var latestObject = this.plantInfo.historyDay[currentDay][key];
            for (var k = 0; k < Object.keys(latestObject).length; k++) {
              numberOfItems++;
              total += Object.values(latestObject)[k];
            }
          }
        }

        avg = total / numberOfItems;
        avg = Math.round(avg * 10) / 10;
        return avg;
      }
    },
    checkButtons() {
      //wait for dom to be fully loaded
      this.$nextTick(() => {
        //first clear all intervals
        clearInterval(this.intervalWater);
        clearInterval(this.intervalFeed);
        clearInterval(this.intervalMeasurement);

        //check if there is any info on the previous buttonclick
        if ("buttonsActions" in this.plantInfo) {
          if ("water" in this.plantInfo.buttonsActions) {
            //store millis
            var timeUntillWater = this.plantInfo.buttonsActions.water
              .timeUntill;
            //check with time now
            if (timeUntillWater > new Date().getTime()) {
              //set interval if time is not yet reached
              this.intervalWater = this.setNewInterval(
                "water",
                timeUntillWater
              );
            } else {
              //else reset buttons
              var waterButton = document.getElementById("water");
              waterButton.disabled = false;
              waterButton.innerHTML =
                'Geef de plant water <span class="text-success">+50 punten</span>';
            }
          }
          if ("voeding" in this.plantInfo.buttonsActions) {
            var timeUntillVoeding = this.plantInfo.buttonsActions.voeding
              .timeUntill;
            //check with time now
            if (timeUntillVoeding > new Date().getTime()) {
              //set interval if time is not yet reached
              this.intervalFeed = this.setNewInterval(
                "voeding",
                timeUntillVoeding
              );
            } else {
              //else reset buttons
              var voedingButton = document.getElementById("voeding");
              voedingButton.disabled = false;
              voedingButton.innerHTML =
                'Geef de plant voeding <span class="text-success">+100 punten</span>';
            }
          }
          if ("meten" in this.plantInfo.buttonsActions) {
            var timeUntillMeten = this.plantInfo.buttonsActions.meten
              .timeUntill;
            //check with time now
            if (timeUntillMeten > new Date().getTime()) {
              //set interval if time is not yet reached
              this.intervalMeasurement = this.setNewInterval(
                "meten",
                timeUntillMeten
              );
            } else {
              //else reset buttons
              var metenButton = document.getElementById("meten");
              metenButton.disabled = false;
              metenButton.innerHTML =
                'Start een meting <span class="text-success">+10 punten</span>';
            }
          }
        }
      });
    },
    setDate: function (day) {
      projectDB
        .ref("users/" + this.userID + "/plants/" + this.$route.params.id)
        .update({
          chosenHistoryDay: day,
        });
    },
    setNewInterval(action, countDownDate) {
      var button = document.getElementById(action);
      button.disabled = true;

      // Update the count down every 1 second
      var x = setInterval(function () {
        var distance = countDownDate - new Date().getTime();
        // Time calculations for hours, minutes and seconds

        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in the clicked button
        button.innerHTML =
          "Weer beschikbaar over: " +
          hours +
          "h " +
          minutes +
          "m " +
          seconds +
          "s ";

        // If the count down is over, write some text and enable
        if (distance < 0) {
          clearInterval(x);
          button.disabled = false;
          if (action == "voeding") {
            button.innerHTML =
              'Geef de plant extra voeding <span class="text-success">+100 punten</span>';
          } else if (action == "water") {
            button.innerHTML =
              'Geef de plant water <span class="text-success">+50 punten</span>';
          } else if (action == "meten") {
            button.innerHTML =
              'Start een meting <span class="text-success">+10 punten</span>';
          }
        }
      }, 1000);
      return x;
    },
    actionButton: function (action, timeOut) {
      //first set the countdown time in firebase (in ms)
      var countDownDate = timeOut + new Date().getTime();
      projectDB
        .ref(
          "users/" +
            this.userID +
            "/plants/" +
            this.$route.params.id +
            "/buttonsActions/" +
            action
        )
        .update({
          timeUntill: countDownDate,
        });

      //then set the xp that has been earned in firebase
      var dayKey = this.todayKey();
      var XP = { xpValue: 0 };
      var XPref = projectDB.ref(
        "users/" + this.userID + "/userStats/" + dayKey + "/XP"
      );

      XPref.get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            XP = snapshot.val();
          }
        })
        .then(() => {
          //add a value to the xpValue
          if (action == "water") {
            XP.xpValue += 50;
          } else if (action == "voeding") {
            XP.xpValue += 100;
          } else if (action == "meten") {
            XP.xpValue += 10;
          }
          XPref.set(XP);
        })
        .then(() => {
          //get the current value of times the action has been executed
          var plantStatsRef = projectDB.ref(
            "users/" +
              this.userID +
              "/plants/" +
              this.$route.params.id +
              "/plantStats/" +
              action
          );
          var prevVal = 0;
          plantStatsRef
            .get()
            .then((snapshot) => {
              //then store value
              if (snapshot.exists()) {
                prevVal = snapshot.val();
              }
            })
            .then(() => {
              //then set new value
              var newVal = prevVal + 1;
              plantStatsRef.set(newVal);
            });
        })
        .then(() => {
          //after that, save stats to public domain to be read by all users
          var allUserStatsRef = projectDB.ref(
            "allUserStats/" + this.userID + "/XP"
          );
          allUserStatsRef.set(XP);
        })
        .catch((error) => {
          console.error(error);
        });
      var actionLoader = document.getElementById("actionLoader");
      if (actionLoader) {
        actionLoader.style.visibility = "visible";
      }

      // send action to RPI
      projectDB.ref("actions/").push({
        action: action,
        sentBy: this.userID,
        plantNumber: this.plantInfo.plantNumber,
        plantKey: this.$route.params.id,
      });
    },
    todayKey() {
      var day = new Date();
      function twoDigit(n) {
        return (n < 10 ? "0" : "") + n;
      }
      return (
        day.getFullYear() +
        "" +
        twoDigit(day.getMonth() + 1) +
        "" +
        twoDigit(day.getDate())
      );
    },
    fillDonutData() {
      if ("plantStats" in this.plantInfo) {
        if ("water" in this.plantInfo.plantStats)
          this.barSections[0].value = this.plantInfo.plantStats["water"];
        if ("voeding" in this.plantInfo.plantStats)
          this.barSections[1].value = this.plantInfo.plantStats["voeding"];
        if ("meten" in this.plantInfo.plantStats)
          this.barSections[2].value = this.plantInfo.plantStats["meten"];
      }
    },
    fillGraphData() {
      if ("historyDay" in this.plantInfo) {
        this.chartDatasets[0].data = this.makeArrayOfObj(
          this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay].water
        );
        this.chartDatasets[1].data = this.makeArrayOfObj(
          this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay].sunlight,
          true
        );
        this.chartDatasets[2].data = this.makeArrayOfObj(
          this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay].humidity
        );
        this.chartDatasets[3].data = this.makeArrayOfObj(
          this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay].temperature
        );
        this.labels.xLabels = this.makeArrayOfObj(
          this.plantInfo.historyDay[this.plantInfo.chosenHistoryDay]
            .measureTimes
        );
      }
    },
  },
};
</script>
<style scoped>
.content {
  background: #f4f3ef;
  background-image: url("~@/assets/backgroundfarm.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom right;

  padding: 100px 10px 0px 10px;
}
@media (min-width: 699px) {
  .content {
    padding: 100px 50px 0px 50px;
  }
}
#donutContent {
  margin-bottom: -10px;
}
</style>