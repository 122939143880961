<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-transparent">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <router-link class="navbar-brand" :to="{ name: 'Home' }"
          ><img
            src="../assets/logo.png"
            alt="The Green's Gambit"
            height="55px"
          />
          Welkom {{ user.displayName }}</router-link
        >
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navigation">
        <ul class="navbar-nav">
          <li class="nav-item btn-rotate dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarPlantsLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-seedling"></i>
              <p>
                <span class="d-lg-none d-md-block">Mijn planten </span>
              </p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarPlantsLink"
            >
              <router-link
                v-for="(plant, key) in plants"
                :key="key"
                class="dropdown-item"
                :to="{ name: 'PlantDetail', params: { id: key } }"
                >{{ plant.plantName }}</router-link
              >

              <div class="dropdown-divider"></div>
              <router-link class="dropdown-item" :to="{ name: 'newPlant' }"
                >Koop een nieuwe plant</router-link
              >
            </div>
          </li>

          <li class="nav-item btn-rotate dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-envelope-open"></i>
              <p>
                <span class="d-lg-none d-md-block">Berichten </span>
                <span class="badge badge-warning ml-1" v-if="messages">{{
                  messages.length
                }}</span>
              </p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <router-link
                v-for="(message, key) in messages"
                :key="key"
                class="dropdown-item"
                :to="{ name: 'PlantDetail', params: { id: message.plantID } }"
                >Jouw {{ message.plantName }} heeft
                {{ message.val.action }} nodig!</router-link
              >

              <div class="dropdown-divider"></div>
              <div v-if="messages">
                <span
                  class="dropdown-item"
                  @click="deleteAllMessages"
                  v-if="messages.length > 0"
                  >Verwijder alle berichten</span
                >
                <span class="dropdown-item" v-else
                  >Er zijn geen berichten op dit moment</span
                >
              </div>
              <div v-else>
                <span class="dropdown-item"
                  >Er zijn geen berichten op dit moment</span
                >
              </div>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link btn-rotate" @click="signOut">
              <i class="fa fa-user"></i>
              <p>
                <span class="d-lg-none d-md-block">Log uit</span>
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { projectAuth, projectDB } from "../js/firebaseConfig";

export default {
  name: "Navbar",

  data() {
    return {
      user: "",
      plants: "",
    };
  },
  computed: {
    messages: function () {
      if (this.plants) {
        var foundMessages = [];
        for (var i = 0; i < Object.keys(this.plants).length; i++) {
          var plantKey = Object.keys(this.plants)[i];
          if ("messages" in this.plants[plantKey]) {
            for (
              var k = 0;
              k < Object.keys(this.plants[plantKey].messages).length;
              k++
            ) {
              var messageKey = Object.keys(this.plants[plantKey].messages)[k];
              foundMessages.push({
                val: this.plants[plantKey].messages[messageKey],
                plantID: plantKey,
                plantName: this.plants[plantKey].plantName,
                key: messageKey,
              });
            }
          }
        }
        var calculatedMessage = [];
        var filteredArray = [];
        if (foundMessages.length > 0) {
          //get all plantid's in a array
          for (var l = 0; l < foundMessages.length; l++) {
            const count = foundMessages.filter(function (item) {
              if (item.plantID === foundMessages[l].plantID) {
                return true;
              } else {
                return false;
              }
            });
            if (filteredArray.length > 0) {
              var canAdd = true;
              for (var j = 0; j < filteredArray.length; j++) {
                if (filteredArray[j][0].plantID === count[0].plantID) {
                  //compare to-be-added whith what is already in so it can be filtered
                  canAdd = false;
                }
              }
              if (canAdd) filteredArray.push(count); //only add if there is no same plant in array
            } else filteredArray.push(count);
          }
        }
        for (var x = 0; x < filteredArray.length; x++) {
          filteredArray[x][0].counter = filteredArray[x].length;
          calculatedMessage.push(filteredArray[x][0]);
        }
      }
      //return foundMessages;
      return calculatedMessage;
    },
  },
  methods: {
    deleteAllMessages() {
      var foundMessages = [];
      if (this.plants) {
        for (var i = 0; i < Object.keys(this.plants).length; i++) {
          var plantKey = Object.keys(this.plants)[i];
          if ("messages" in this.plants[plantKey]) {
            for (
              var k = 0;
              k < Object.keys(this.plants[plantKey].messages).length;
              k++
            ) {
              var messageKey = Object.keys(this.plants[plantKey].messages)[k];
              foundMessages.push({
                plantID: plantKey,
                key: messageKey,
              });
            }
          }
        }
      }
      foundMessages.forEach((message) => {
        const dbRef = projectDB.ref();
        dbRef
          .child("users")
          .child(this.user.uid)
          .child("plants")
          .child(message.plantID)
          .child("messages")
          .child(message.key)
          .remove();
      });
    },
    twoDigit(n) {
      return (n < 10 ? "0" : "") + n;
    },
    async signOut() {
      // once every logout, save login to database for stats
      var day = new Date();
      var dayKey =
        day.getFullYear() +
        "" +
        this.twoDigit(day.getMonth() + 1) +
        "" +
        this.twoDigit(day.getDate());
      var timenow =
        this.twoDigit(day.getHours()) +
        ":" +
        this.twoDigit(day.getMinutes()) +
        ":" +
        this.twoDigit(day.getSeconds());
      var loginRef = projectDB.ref(
        "users/" + this.user.uid + "/userStats/" + dayKey + "/loginHistory"
      );
      loginRef.push(timenow);
      try {
        await projectAuth.signOut().then(() => {
          //router.push('/auth')
        });
      } catch (error) {
        console.log(error.message);
      }
    },
  },

  beforeCreate() {
    projectAuth.onAuthStateChanged((_user) => {
      if (_user) {
        //this.writeData(_user)
        this.user = _user;
        var userData = projectDB.ref("users/" + _user.uid);
        userData.on("value", (snapshot) => {
          var data = snapshot.val();
          this.plants = data.plants;
        });
      } else {
        //router.push("/auth");
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
nav {
  background-color: #f4f3ef;
}
</style>
