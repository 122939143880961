<template>
  <div class="wrapper">
    <div class="container" style="height: 100vh">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-6 card p-4 bg-light">
          <img
            class="card-img-top"
            src="../assets/404_2.jpg"
            alt="No plant found"
          />
          <div class="">
            <h2 class="text-center pt-4">Oh nee!</h2>
          </div>
          <p class="text-center card-body pt-3">
            Het lijkt er op alsof dit plantje niet (meer) bestaat.
          </p>

          <router-link type="button" class="btn btn-info" :to="{ name: 'Home' }"
            >Ga terug naar de homepage</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>
.wrapper{
  background: #f4f3ef;
  background-image: url("~@/assets/backgroundfarm.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom left;

}
</style>