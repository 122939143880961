<template>
<div class="wrapper">
  <div class="container" style="height: 100vh">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-6 card p-4 bg-light">
      <img src="../assets/logo.png" class="mx-auto d-block" alt="The Green's Gambit" width="200px">
          <h2 class="text-center card-title pt-4">
            Welkom boer(in)!
          </h2>
         
        <p class="text-center card-text pt-3">
          
          Om deze app te gebruiken moet je een account hebben.
        </p>
        <div id="firebaseui-auth-container"></div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { uiConfig, projectAuth } from "../js/firebaseConfig";

export default {
  name: "Auth",
  mounted() {
    var ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(projectAuth);
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
<style scoped>
.wrapper {
  background: #f4f3ef;
  background-image: url("~@/assets/backgroundfarm.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom left;

}
.card-img-top {
  border-top-left-radius: calc(0.8rem - 1px);
  border-top-right-radius: calc(0.8rem - 1px);
}
.card {
  border-radius: 0.8rem;
}
</style>