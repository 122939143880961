import Vue from 'vue'
import App from './App.vue'
import bootstrap from 'bootstrap'
import router from './router'
import { BootstrapVue, IconsPlugin, BTooltip,  DropdownPlugin , BSpinner } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSync, faHistory, faCalendarAlt, faThermometerThreeQuarters, faSun, faTint, faFaucet, faSeedling ,faUser, faExclamationCircle, faCheck, faEnvelopeOpen, faChartBar, faArrowAltCircleUp, faTractor, faUsers, faCrown, faCoins} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

Vue.use(Donut);
library.add(faSync, faHistory, faCalendarAlt, faThermometerThreeQuarters, faSun, faTint, faFaucet,faChartBar,faCoins,faUsers,faCrown, faSeedling,faArrowAltCircleUp,faTractor ,faUser, faExclamationCircle, faCheck, faEnvelopeOpen)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-spinner', BSpinner)
Vue.component("b-tooltip", BTooltip);
Vue.use(DropdownPlugin);

Vue.config.productionTip = false
import { dom } from '@fortawesome/fontawesome-svg-core'

dom.watch() 

new Vue({
  bootstrap,
  BootstrapVue,  
  IconsPlugin,
  router,
  render: h => h(App)
}).$mount('#app')
