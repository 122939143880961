<template>
  <div v-if="onboarding.currentStep == 0">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-6 card p-4 bg-light">
        <img
          class="card-img-top"
          src="../assets/cardimg2.jpg"
          alt="Welcome to the club"
          height="100px"
        />
        <div class="card-img-overlay">
          <h2 class="text-center card-title text-light pt-4">Goei'n dag!</h2>
        </div>
        <p class="text-center card-text pt-3">
          Welkom bij de Forsaken Farm {{ userdata.displayName }}, laten we
          beginnen bij het begin... <br /><br />
          De boer heeft zijn boerderij niet zo netjes achter gelaten na de
          uitbraak van de gekke-planten-ziekte. Het is nu aan jou om zijn
          boerderij in ere te herstellen. Je kan dit doen door verschillende
          planten van water en voeding te voorzien, ze op te meten en natuurlijk
          veel liefde te geven.

          <br /><br />
          Het is maar goed dat de boer zijn installatie heeft aan laten staan
          toen hij vertok, jij hebt nu exclusief toegang tot een deel van zijn
          boerderij. Je kan live zien wat er in het echt gebeurt met jouw
          planten en de camera legt dit allemaal vast. Je bent nu onderdeel van
          een clubje met de meest technologisch geadvanceerde boeren van het
          land.
          <br /><br />Denk er wel aan, de planten kunnen niet zonder de boer!
          Een grote verandwoordelijkheid... want anders komt de
          gekke-planten-ziekte misschien wel terug en is al het harde werk voor
          niets.
        </p>
        <button class="btn btn-dark text-center FFbutton" @click="nextStep">
          Laten we beginnen!
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="onboarding.currentStep == 1" class="container">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-8 m-2 card p-4 bg-light">
        <img
          class="card-img-top"
          src="../assets/cardimg2.jpg"
          alt="Kies een plant"
          height="100px"
        />
        <div class="card-img-overlay">
          <h2 class="text-center card-title text-light pt-4">
            De eerste plant
          </h2>
        </div>
        <p class="text-center card-text pt-3">
          Je mag nu jouw eerste plant kiezen, iedere plant heeft zijn eigen
          voorkeuren en moeilijkheidsgraad.<br />
          Kies uit een van de onderstaande planten.
        </p>
        <div class="row justify-content-center">
          <div
            v-for="plant in plantsToChoose"
            :key="plant.index"
            class="col-lg-6 p-0"
          >
            <div class="card m-2">
              <img
                class="card-img-top"
                :src="require('../assets/' + plant.imgURL)"
                :alt="plant.name"
              />
              <div class="card-body">
                <h5 class="card-title">{{ plant.name }}</h5>
                <p class="card-text">{{ plant.plantInformation }}</p>

                <button
                  type="button"
                  @click="choosePlant(plant)"
                  class="btn btn-outline-success"
                >
                  Kies
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center FFbutton">
          <button class="btn btn-outline-secondary m-2" @click="prevStep">
            Terug
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectDB } from "../js/firebaseConfig";

export default {
  name: "onboarding",
  props: {
    userdata: {},
  },
  data() {
    return {
      onboarding: { currentStep: 0, maxstep: 2 },
      plantsToChoose: [
        {
          name: "Tuinkers",
          plantInformation:
            "Tuinkers is een eenjarige plant die heel snel kiemt en groeit. Snel resultaat dus! Je kunt er vooral in de koelere maanden van het jaar regelmatig blaadjes van oogsten. Tuinkers houdt van een koele en vochtige grond en zeker niet te veel zonlicht.",
          imgURL: "tuinkers.jpg",
        },
        {
          name: "Radijs",
          plantInformation:
            "Ben je een leek in de kunst van het moestuinieren? Start dan met radijs, want daarmee kan het bijna niet misgaan. Zaai van maart tot augustus; radijsjes kiemen vervolgens snel en groeien makkelijk. Oogsten kan al na zes tot acht weken. De grond hoeft niet kletsnat te zijn, maar ook zeker niet uitgedroogd.",
          imgURL: "radijsjes.jpg",
        },
        {
          name: "Courgette",
          plantInformation:
            "Voor courgettes hoef je geen groene vingers te hebben; als de plant eenmaal aanslaat, zoekt hij vanzelf zijn weg door je moestuin. Hij groeit echt goed als de temperaturen wat hoger liggen. Een courgette groeit hartstikke snel; na zo’n zes weken is oogsten al mogelijk. Vocht zorgt bij deze moestuinplanten vaak voor meeldauw. Geef ze genoeg water, maar houd een oogje in het zeil. ",
          imgURL: "courgettes.jpg",
        },
        {
          name: "Wortelen",
          plantInformation:
            "Het kan even duren voor je ze kunt oogsten (zo’n drie maanden), maar dan heb je ook wat.  Geduld is bij wortelen wel geboden; kiemen kan al wel zo’n drie tot vier weken duren. Verder heb je er weinig omkijken naar, ze vallen onder de makkelijke groenten. Wortels hebben niet veel voedsel nodig en soms wat water. Alvast wat lekkers kweken voor Amerigo?",
          imgURL: "wortelen.jpg",
        },
      ],
    };
  },

  methods: {
    choosePlant(plant) {
      //get a new plant id (from stack)
      var ID;
      const dbRef = projectDB.ref("availablePlantID");
      dbRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            var data = snapshot.val();
            ID = Object.values(data)[0]; //get first available plant id -> if no plants are available then there will be no snapshot.
            console.log(ID);
            this.initialObj(plant, ID);
            dbRef.child(Object.keys(data)[0]).remove(); // remove plant id from stack
          } else {
            console.log("No plant available at the moment!");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    nextStep() {
      if (this.onboarding.currentStep < this.onboarding.maxstep) {
        this.onboarding.currentStep++;
      }
    },
    prevStep() {
      if (this.onboarding.currentStep > 0) {
        this.onboarding.currentStep--;
      }
    },todayKey() {
      var day = new Date();
      function twoDigit(n) { // format int under 10 to 01 or 02 for proper date 
        return (n < 10 ? "0" : "") + n;
      }
      return (
        day.getFullYear() +
        "" +
        twoDigit(day.getMonth() + 1) +
        "" +
        twoDigit(day.getDate())
      );
    },
    initialObj(plant, nextPlantID) {
      // Get a key for a new plant.
      var newPlantKey = projectDB
        .ref("users/" + this.userdata.uid + "/plants")
        .push().key;
      
      var dayKey = this.todayKey()
      //push initial data for rendering page
      projectDB
        .ref("users")
        .child(this.userdata.uid)
        .child("plants")
        .child(newPlantKey)
        .child("chosenHistoryDay")
        .set(dayKey);
      projectDB
        .ref("users")
        .child(this.userdata.uid)
        .child("plants")
        .child(newPlantKey)
        .child("plantName")
        .set(plant.name);

      projectDB
        .ref("users")
        .child(this.userdata.uid)
        .child("plants")
        .child(newPlantKey)
        .child("plantNumber")
        .set(nextPlantID);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.FFbutton {
  z-index: 100;
}
</style>
