import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'


const firebaseConfig = {
  apiKey: "AIzaSyCwAWn2CM6I_Rt4ZZcf1v0seJ_kCtF9bTk",
  authDomain: "forsakenfarm-33106.firebaseapp.com",
  projectId: "forsakenfarm-33106",
  storageBucket: "forsakenfarm-33106.appspot.com",
  messagingSenderId: "245900839556",
  appId: "1:245900839556:web:292ae56c5671230fe790d1",
  measurementId: "G-T24R8MQ1LB"
};

var uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
}
// init firebase
firebase.initializeApp(firebaseConfig)

// init services

const projectAuth = firebase.auth()
const projectDB = firebase.database()

const getUser = () => {
  var user = projectAuth.currentUser
  console.log("user request complete")
  return { user }
}

export { projectAuth, projectDB, uiConfig, getUser }
