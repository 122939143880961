<template>
  <div class="chart" v-if="checkSize()">
    <TrendChart
      :datasets="this.chartDatasets"
      :grid="this.grid"
      :labels="this.labels"
      :min="0"
      :interactive="true"
      @mouse-move="onMouseMove"
      class="trend-chart"
    >
    </TrendChart>
    <div
      id="pop"
      role="tooltip"
      ref="tooltip"
      class="tooltip"
      :class="{ 'is-active': popperIsActive }"
    >
      <div class="tooltip-container" v-if="tooltipData">
        <h6 class="text-center">
          <strong>{{ labels.xLabels[tooltipData.index] }}</strong>
        </h6>
        <img
          :src="Object.values(imgURL)[tooltipData.index]"
          alt="Shot not found"
          width="150px"
          class="d-block"
        />
        <div class="tooltip-data">
          <div class="tooltip-data-item tooltip-data-item--1">
            {{ tooltipData.data[0] }}
          </div>
          <!-- lux is calculated based on /300 on importing so for the label = *300 -->
          <div class="tooltip-data-item tooltip-data-item--2">
            {{ tooltipData.data[1]*300 }}
          </div>
          <div class="tooltip-data-item tooltip-data-item--3">
            {{ tooltipData.data[2] }}
          </div>
          <div class="tooltip-data-item tooltip-data-item--4">
            {{ tooltipData.data[3] }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart" v-else>
    <h5 class="text-center">Grafiek nog niet beschikbaar</h5>
  </div>
</template>

<script>
import TrendChart from "vue-trend-chart";
import { createPopper } from "@popperjs/core";
import Vue from "vue";
Vue.use(TrendChart);

export default {
  name: "Chart",
  data() {
    return {
      tooltipData: null,
      popper: null,
      popperInit: false,
      popperIsActive: false,
      grid: {
        verticalLines: true,
        horizontalLines: true,
      },
    };
  },
  props: {
    chartDatasets: {},
    labels: {},
    imgURL: {},
  },
  
  mounted() {
    this.setupPopper();
  },
  methods: {
    setupPopper() {
      if (!this.popperInit & this.checkSize()) {
        this.initPopper();
        this.popperInit = true;
      }
    },
    checkSize() {
      return this.chartDatasets[0].data.length > 1;
    },
    initPopper() {
      const chart = document.querySelector(".trend-chart");
      const ref = chart.querySelector(".active-line");
      const tooltip = this.$refs.tooltip;
      this.popper = createPopper(ref, tooltip, {
        placement: "left",
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, 10] },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: chart,
            },
          },
        ],
      });
    },
    onMouseMove(params) {
      this.setupPopper();
      this.popperIsActive = !!params;
      this.popper.update();
      this.tooltipData = params || null;
    },
  },
};
</script>

<style >
.chart {
  width: 100%;
}
.chart .vtc {
  height: 250px;
  font-size: 12px;
}
@media (min-width: 699px) {
  .chart .vtc {
    height: 320px;
  }
}
.chart .labels {
  stroke: rgba(0, 0, 0, 0.05);
}
.chart .active-line {
  stroke: rgba(0, 0, 0, 0.2);
}
.chart .point {
  stroke-width: 2;
  transition: stroke-width 0.2s;
}
.chart .point.is-active {
  stroke-width: 5;
}
.chart .curve1 .stroke {
  stroke: #fbac91;
  stroke-width: 2;
}
.chart .curve1 .fill {
  fill: #fbac91;
  opacity: 0.05;
}
.chart .curve1 .point {
  fill: #fbac91;
  stroke: #fbac91;
}
.chart .curve2 .stroke {
  stroke: #fbe1b6;
  stroke-width: 2;
}
.chart .curve2 .point {
  fill: #fbe1b6;
  stroke: #fbe1b6;
}
.chart .curve3 .stroke {
  stroke: #7fdfd4;
  stroke-width: 2;
}
.chart .curve3 .point {
  fill: #7fdfd4;
  stroke: #7fdfd4;
}
.chart .curve4 .stroke {
  stroke: #7f86df;
  stroke-width: 2;
}
.chart .curve4 .point {
  fill: #7f86df;
  stroke: #7f86df;
}
.chart .tooltip {
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 1;
}
.chart .tooltip:not(.is-active) {
  display: none;
}

.chart .tooltip-data-item {
  display: flex;
  align-items: center;
}

.chart .tooltip-data-item:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.chart .tooltip-data-item--1:before {
  background: #fbac91;
}
.chart .tooltip-data-item--1::after {
  content: "% grondvocht";
}
.chart .tooltip-data-item--2:before {
  background: #fbe1b6;
}
.chart .tooltip-data-item--2::after {
  content: "lux";
  padding-left: 5px;
}
.chart .tooltip-data-item--3:before {
  background: #7fdfd4;
}
.chart .tooltip-data-item--3::after {
  content: "% luchtvochtigheid";
}
.chart .tooltip-data-item--4:before {
  background: #7f86df;
}
.chart .tooltip-data-item--4::after {
  content: "°C";
}
.x-labels .label text {
  transform: translate(-20px, 5px) rotate(-45deg);
}
</style>